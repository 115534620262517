<template>
  <div :class="`${customClass}`">
    <!-- <label class="block text-sm font-medium text-gray-700">{{ label }}</label> -->
    <Text
        v-if="label"
        weight="semibold"
        size="sm"
        color="gray-700"
        custom-class="block"
        :content="label"
    />
    <div class="relative mt-1 rounded-md shadow-sm ">
      <div
        class="absolute inset-y-0 right-0 flex items-center px-3 border-r"
      >
        <Icon 
          name="DocumentDuplicateIcon" 
          is-outline color="gray-400" 
          customClass="flex-shrink-0 mr-1 cursor-pointer" 
          hoverTextColor="gray-500"
          size="5"
          @click="copy"
          />
       
      </div>
      <input
        type="text"
        ref="inputField" 
        :readonly ="disabled" 
        autocomplete="off"
        v-model="inputValue"
        class="block w-full h-10 pr-10 pl-3 border border-gray-300 rounded sm:text-sm focus:outline-none focus:ring-1 focus:ring-indigo-500"
        :placeholder="placeholder"
        :class="`${disabled ? 'text-gray-500' : ''}`"
        style="min-width: 150px !important;"
      />
    </div>
  </div>
</template>

<script>
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";

/**
 * - Use it if needs to show input form with leading icon
 * - icon is required
 */
export default {
  components: {
    Icon,
    Text
  },
  props: {
    /**
     * Use to show what input form is for
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Placeholder of input form
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Use it to apply extra class to input form
     */
    customClass: {
      type: String,
      default: "",
    },
    /**
     * Use to catch dynamic value of input form
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Use to specify leading icon. its required. ex: MailIcon
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
  methods: {
    copy(e) {
      e.preventDefault();
      this.$refs.inputField.focus();
      this.$refs.inputField.select();
      document.execCommand('copy');
    }
  }
};
</script>
