<template>
	<div class="w-full bg-white">
		<div class="py-6 px-4 overflow-hidden flex justify-center items-center sm:px-6 lg:px-8 md:justify-between">
			<img src="../../../../assets/images/dscvry_logo_D_light-grey.png" class="h-5 hidden md:block" />
			<img src="../../../../assets/images/dscvry_logo-grey.png" class="h-5" />
		</div>
	</div>
</template>

<script>

export default {
	name: "SimpleWithLogo"
}
</script>
