<template>
	<footer class="w-full flex justify-between max-w-5xl items-center py-2 px-2"
		:class="currentStep !== 0 && currentStep !== steps - 1 ? 'bg-white h-16 sm:h-24' : 'h-auto'">
		<div v-if="currentStep !== 0 && currentStep !== steps - 1" class="flex items-center">
			<div
				class="h-11 w-11 rounded-full bg-white text-sm font-semibold text-gray-900 border-2 items-center flex justify-center">
				{{ currentStep + 1 }}/{{ steps }}</div>
			<ButtonWithIcon v-if="currentStep > 0" variant="default" icon-name="ArrowSmLeftIcon" icon-size="5"
				text-color="dark_indigo" size="md" label="Back" leading is-outline :click="() => $emit('onBack')" is-show-text
				custom-class="bg-white shadow-none" />
		</div>
		<div v-if="currentStep !== 0 && currentStep !== steps - 1">
			<ButtonWithIcon v-if="isShowBtn && !isDemographic" variant="default"
				:icon-name="currentStep === 0 ? '' : 'ArrowSmRightIcon'" icon-size="5" text-color="white" size="md"
				:label="currentStep === 0 ? 'Get Started' : `Next`" is-outline is-show-text :click="() => $emit('onNext')"
				custom-class="bg-dark_indigo" :disabled="!isValid" :isLoading="isLoading" />
			<ButtonWithIcon v-else-if="isDemographic" variant="default" icon-name="ThumbUpIcon" icon-size="5" text-color="white"
				:isLoading="isLoading" size="md" label="Finish" is-outline-icon is-show-text :click="() => $emit('onNext')"
				custom-class="bg-dark_indigo" :disabled="!isValid" />
		</div>
	</footer>
</template>

<script>
import ButtonWithIcon from "../../../molecules/Button/WithIcon/WithIcon.vue"
export default {
	components: {
		ButtonWithIcon
	},
	props: {
		steps: {
			type: Number,
			default: 0,
		},
		currentStep: {
			type: Number,
			default: 0
		},
		isShowBtn: {
			type: Boolean,
			default: false,
		},
		isDemographic: {
			type: Boolean,
			default: false,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: false,
		}
	}
}
</script>
